export enum LOCAL_CONSTANT {
  USER_TOKEN = 'USER_TOKEN',
  USER = 'USER',
  RESET_TOKEN = 'RESET_TOKEN',
  EVENT_GROUP = 'EVENT_GROUP',
  VENUES = 'VENUES',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
}

export enum UserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
  CLIENT = 'CLIENT',
}

export enum STATUS {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}
